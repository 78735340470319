<template>

 	<div id="Receita" v-if="dataReady">

		<div class="container">
			<div class="receita-image moldura4">
				<img :src="pageBaseUrl+'/'+receita.image.path" :alt="receita.titulo">
			</div>
		</div>

 		<div class="container-fluid mt80 mb80 text-center">
			<div class="container">
				<div class="col-12 col-lg-10 offset-lg-1">
					<img src="@/images/textos/receita.svg" class="mb20">
					<h1>{{ receita.titulo }}</h1>
					<hr class="chef-line">
					<p class="chef">Do Chef {{ receita.chef }}</p>
				</div>
			</div>
		</div>

		<div class="container mb80">

			<div class="row video-receita" v-if="receita.videoid">
				<div class="col-12 col-lg-8 offset-lg-2 moldura4 embed-responsive embed-responsive-16by9">
					<iframe :src="'https://www.youtube.com/embed/' + receita.videoid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<div class="col-4 offset-8 col-md-3 offset-md-8 col-lg-2 offset-lg-0 mt10 d-flex align-items-center">
					<img src="@/images/veja-video-top.svg" style="max-width:100%;" alt="Veja o vídeo" class="d-block d-lg-none">
					<img src="@/images/veja-video-side.svg" style="max-width:100%;" alt="Veja o vídeo" class="d-none d-lg-block">
				</div>
			</div>

			<div class="row receita">

				<div id="ingredientes" class="col-10 offset-1 col-md-6 offset-md-0 col-lg-4 offset-lg-2 mb80">

					<h3 class="mb30">
						<img src="@/images/textos/ingredientes.svg" style="max-width:100%;" alt="Ingredientes"><br>
						<span class="doses">{{ receita.doses }} PESSOAS</span>
					</h3>
					
					<ul class="mb30">
						<li v-for="ingrediente in receita.ingredientes" :key="ingrediente.value">
							<span>{{ ingrediente.value }}</span>
						</li>
					</ul>

					<span class="text-primary text-uppercase">Tempo de Preparação:</span><br>
					{{ receita.tempoprep }}<br>
					<br>
					<span class="text-primary text-uppercase">Tempo de Cozedura:</span><br>
					{{ receita.tempocoz }}

				</div>

				<div id="preparacao" class="col-10 offset-1 col-md-6 offset-md-0 col-lg-4 mb80">
					<h3 class="mb30">
						<img src="@/images/textos/preparacao.svg" style="max-width:100%;" alt="Preparação">
					</h3>
					{{ receita.preparacao }}
				</div>

				<a :href="'https://www.facebook.com/sharer/sharer.php?u=https:'+this.pageShareUrl" target="_blank" id="fb-share" class="col-12 d-flex flex-row justify-content-center">
					<p><img src="@/images/facebook.svg"></p> 
					<p>PARTILHE COM OS SEUS AMIGOS</p>
				</a>


			</div>

		</div>

		<MainFooter />

 	</div>

</template>

<script>
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import axios from "axios";

export default {
	name: "Receita",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			receitaSlug: this.$route.params.slug,
			receita: null,
			pageBaseUrl: globals.BASE_URL,
			pageShareUrl: null
		};
	},
	beforeCreate: function() {
        document.body.className = 'pag-receita';
    },
	mounted () {
		this.encodeFacebookUrl();
		axios
			.get(globals.COCKPIT_SERVER+"collections/get/receitas"+globals.COCKPIT_TOKEN+"&filter[titulo_slug]="+this.receitaSlug)
			.then(response => {
				this.receita = response.data.entries[0]
				this.dataReady = true
				this.changeMetaData()
			})
		;
	},
	metaInfo() {
		return {
			title: this.receita.titulo
		}
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = this.receita.titulo;
			this.$parent.metaDescription = 'Conheça as receitas mais saborosas com batatas fritas Cerro da Águia!';
			this.$parent.metaImage = globals.BASE_URL + this.receita.image.path
		},
		encodeFacebookUrl: function () {
			this.pageShareUrl = encodeURIComponent(globals.BASE_URL + this.$route.fullPath);
		}
	
	}
};
</script>

